import { useMemo } from 'react';

import amplitude from 'amplitude-js';

export const useAmplitude = () => {
  const logEvent = useMemo(() => {
    return (event: string, params: object = {}) => {
      // To enable Amplitude testing locally, add `|| true` in this if check
      if (process.env.NODE_ENV === 'production') {
        const tracker = amplitude.getInstance();
        setTimeout(() => {
          const initialized = tracker;
          return initialized
            ? tracker.logEvent(event, params)
            : console.error(`Amplitude: Failed to log event ${event}`);
        });
      } else {
        // log event in the console
        console.log(`Amplitude Event: ${event}`);
        console.log(`Amplitude Event Params: ${JSON.stringify(params)}`);
      }
    };
  }, []);

  const initAmplitude = useMemo(() => {
    return () => {
      const amplitudeAPIKey = process.env.REACT_APP_AMPLITUDE_API_KEY ?? '';
      amplitude.getInstance().init(amplitudeAPIKey);
      setTimeout(() => {
        if (!amplitude.getInstance()) {
          console.error('Amplitude: Failed to initialize');
        }
      });
    };
  }, []);

  return {
    initAmplitude,
    logEvent
  };
};

export default useAmplitude;
