/** @format */

import { Grid, useMediaQuery, Typography } from '@mui/material';
import { useState } from 'react';
import { PrimaryBtn } from '../_customMui/PrimaryBtn';
import { useTheme } from '@mui/material/styles';
import { StandardTextField } from '../_customMui/StandardTextInput';
import { PasswordTextField } from '../_customMui/PasswordTextField';
import { AuthenticationFormCard } from '../_customMui/AuthenticationFormCard';
import { useValidations } from '../../hooks/useValidations';

interface AuthenticationFormProps {
  operation: string;
}

const AuthenticationForm = ({ operation }: AuthenticationFormProps) => {
  const theme = useTheme();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const { validateEmail, validatePassword } = useValidations();
  const buttonDisabled =
    operation !== 'forgot-password'
      ? !validatePassword(password) || !validateEmail(email)
      : !validateEmail(email);
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const handlePasswordChange = (p: string) => {
    setPassword(p);
  };

  const getButtonTitle = () => {
    if (operation === 'forgot-password') return 'Send';
    else return operation === 'sign-up' ? 'Sign Up' : 'Login';
  };
  const getLinkTitle = () => {
    if (operation === 'forgot-password') return 'Still need help? ';
    else return operation === 'sign-up' ? 'Already have an account? ' : "Don't have an account? ";
  };
  const getLinkTarget = () => {
    if (operation === 'forgot-password') return 'support';
    else return operation === 'sign-up' ? 'login' : 'sign-up';
  };
  const getLinkTargetTitle = () => {
    if (operation === 'forgot-password') return 'Contact support';
    else return operation !== 'sign-up' ? 'Sign Up' : 'Login';
  };
  const getMainTitle = () => {
    if (operation === 'forgot-password') return 'Password reset';
    else return operation === 'sign-up' ? 'Sign up. Its free.' : 'Welcome back';
  };

  const linkStyling = () => ({
    fontFamily: theme.fontFamilies?.inter,
    color: theme.palette?.primary?.main,
    textDecoration: 'none'
    // textAlign: "left",
  });

  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      style={{ padding: '12px', textAlign: 'center' }}
    >
      {!mobile && (
        <Typography variant="h5" style={{ marginBottom: '12px' }}>
          {getMainTitle()}
        </Typography>
      )}
      <AuthenticationFormCard elevation={0}>
        {operation === 'forgot-password' && (
          <p style={{ paddingBottom: '22px', paddingTop: '0px', marginTop: '0px' }}>
            Enter your account email address. We'll send you a password-reset link.
          </p>
        )}
        <StandardTextField
          fullWidth={true}
          label="Email address"
          variant="outlined"
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
        {operation !== 'forgot-password' && (
          <PasswordTextField handleChange={handlePasswordChange} />
        )}
        <Grid container direction="row" sx={{ padding: '0 0 12px' }}>
          {operation === 'login' && (
            <a
              href="/forgot-password"
              style={{
                textAlign: 'left',
                fontFamily: theme.fontFamilies?.inter,
                color: theme.palette?.primary?.main,
                fontSize: '13px',
                textDecoration: 'none'
              }}
            >
              Forgot password?
            </a>
          )}
        </Grid>
        <PrimaryBtn disabled={buttonDisabled} fullWidth>
          {getButtonTitle()}
        </PrimaryBtn>
        <p>
          {getLinkTitle()}
          <a href={`/${getLinkTarget()}`} style={linkStyling()}>
            {getLinkTargetTitle()}
          </a>
        </p>
      </AuthenticationFormCard>
    </Grid>
  );
};
export default AuthenticationForm;
