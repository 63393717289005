import AuthenticationForm from '../../components/AuthenticationForm';
import SocialSignOn from '../../components/SocialSignOn';
import SignUpBannerImg from '../../components/SignUpBanner/Mobile';
import AuthDivider from '../../components/AuthDivider/index';
import SignUpBannerDesktop from '../../components/SignUpBanner/Desktop';
import { Grid, Card, useMediaQuery } from '@mui/material';
import { Terms } from '../../components/RecaptchaTerms';
import { useTheme } from '@mui/material/styles';
import { useNavigationStore } from '../../state/navigation/index';
import { useAmplitude } from '../../hooks/useAmplitude';
import { DASHBOARD_LOGIN, DASHBOARD_SIGNUP, VIEWED } from '../../lib/tracking';
import { useEffect } from 'react';

interface SignUpProps {
  operation: 'sign-up' | 'login' | 'forgot-password';
}

const SignUp = ({ operation }: SignUpProps) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const { setShowAppBar, setAppBarStyle } = useNavigationStore();
  const { logEvent } = useAmplitude();

  useEffect(() => {
    if (
      !mobile &&
      (operation === 'login' || operation === 'sign-up' || operation === 'forgot-password')
    ) {
      setShowAppBar(true);
      setAppBarStyle(operation);
    } else {
      setShowAppBar(false);
    }
  }, [mobile, operation, setAppBarStyle, setShowAppBar]);

  useEffect(() => {
    if (operation === 'login') {
      logEvent(`${DASHBOARD_LOGIN}.${VIEWED}`);
    } else if (operation === 'sign-up') {
      logEvent(`${DASHBOARD_SIGNUP}.${VIEWED}`);
    }
  }, [logEvent, operation]);

  return (
    <Grid
      container
      justifyContent="center"
      direction="row"
      alignItems="center"
      sx={{ minHeight: operation !== 'sign-up' && mobile ? '' : '100vh' }}
    >
      {mobile && (
        <Grid item xs={12} md={4}>
          <SignUpBannerImg operation={operation} />
        </Grid>
      )}
      <Grid xs={12} md={6} item>
        <Card sx={{ background: 'transparent' }} elevation={0}>
          <AuthenticationForm operation={operation} />
          {operation !== 'forgot-password' && <AuthDivider />}
          {operation !== 'forgot-password' && <SocialSignOn operation={operation} />}
          {operation === 'sign-up' && <Terms />}
        </Card>
      </Grid>
      {!mobile && operation === 'sign-up' && (
        <Grid item xs={12} md={6}>
          <SignUpBannerDesktop operation={operation} />
        </Grid>
      )}
    </Grid>
  );
};

export default SignUp;
