import axios from 'axios';

const api = axios.create({
  baseURL: 'https://spring-dashboard-stylesheet-dev.s3.us-east-2.amazonaws.com',
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(async (config) => {
  return config;
});

export default api;
