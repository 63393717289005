import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const OutlinedTransparentBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: 'none',
  color: theme.palette?.common?.black,
  border: `solid 1px ${theme.palette.text.primary}`,
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: 'none'
  },
  '&:active': {
    backgroundColor: 'none'
  },
  borderRadius: '16px',
  fontFamily: theme.fontFamilies?.inter,
  padding: '11px',
  textTransform: 'none',
  fontSize: '15px',
  lineHeight: '26px',
  '&:focus': {},
  '&:disabled': {
    backgroundColor: theme.palette?.action?.disabledBackground
  }
}));
