/** @format */

import { Card, Grid, Typography } from '@mui/material';
import './SignUpBanner.css';

interface BannerProps {
  operation: string;
}

const SignUpBannerImg = ({ operation }: BannerProps) => {
  return (
    <Card
      sx={{
        height: 'calc(100vh - 32px)',
        width: 'calc(100%-32px)',
        padding: '16px',
        backgroundColor: 'white'
      }}
      elevation={0}
    >
      <Card
        sx={{
          height: '100%',
          width: '100%',
          borderRadius: '20px',
          background: "url('img/background.svg')",
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
        elevation={0}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          style={{ minHeight: '100%', maxWidth: '631px', margin: '64px auto' }}
        >
          <Typography variant="h2" style={{ margin: '0 auto', textAlign: 'center' }}>
            Create & sell products for free.
          </Typography>
          <Typography
            variant="h5"
            style={{
              padding: '0',
              textAlign: 'center',
              margin: '12px 16px 48px'
            }}
          >
            Monetize your brand in minutes.
          </Typography>
          <img src="/img/sign-up-feature.png" style={{ width: '100%' }} alt="signup" />
        </Grid>
      </Card>
    </Card>
  );
};

export default SignUpBannerImg;
