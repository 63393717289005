/** @format */

import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AuthenticationFormCard } from '../_customMui/AuthenticationFormCard';

export const Terms = () => {
  const theme = useTheme();
  return (
    <Grid container justifyContent="center" direction="row" alignItems="center">
      <AuthenticationFormCard
        sx={{
          textAlign: 'center',
          padding: '0px',
          '& p': {
            fontFamily: theme.fontFamilies?.inter,
            color: theme.palette.text?.secondary,
            fontSize: '12px'
          }
        }}
        elevation={0}
      >
        <Typography variant="caption" style={{ color: theme.palette.text?.secondary }}>
          By creating your account, you agree to our Terms of Service and Privacy Policy.
          <div style={{ margin: '13px' }} />
          This site is protected by reCAPTCHA
        </Typography>
      </AuthenticationFormCard>
    </Grid>
  );
};
