// Project
export const DASHBOARD = 'dashboard_v2';

// Pages
export const DASHBOARD_HOME = `${DASHBOARD}.home`;
export const DASHBOARD_LOGIN = `${DASHBOARD}.login`;
export const DASHBOARD_SIGNUP = `${DASHBOARD}.signup`;

// User Events
export const VIEWED = 'viewed';
