import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const PrimaryBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: '16px',
  backgroundColor: theme.palette?.primary?.main,
  fontFamily: theme.fontFamilies?.inter,
  color: theme.palette?.common?.white,
  boxShadow: theme.shadows[2],
  padding: '11px',
  textTransform: 'none',
  fontSize: '15px',
  lineHeight: '26px',
  '&:hover': {
    backgroundColor: theme.palette?.action?.hover,
    boxShadow: 'none'
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: theme.palette?.primary?.main
  },
  '&:focus': {},
  '&:disabled': {
    boxShadow: 'none',
    backgroundColor: theme.palette?.action?.disabledBackground,
    fontFamily: theme.fontFamilies?.inter
  }
}));
