import create from 'zustand';

interface NavigationState {
  showAppBar: boolean;
  navOpen: boolean;
  appBarStyle: string;
  setAppBarStyle: (style: string) => void;
  setShowAppBar: (toggle: boolean) => void;
  toggleNav: (toggle: boolean) => void;
}

export const useNavigationStore = create<NavigationState>()((set) => ({
  showAppBar: false,
  navOpen: false,
  appBarStyle: 'base',
  setAppBarStyle: (style) => {
    set({ appBarStyle: style });
  },
  setShowAppBar: (toggle) => {
    set({ showAppBar: toggle });
  },
  toggleNav: (toggle) => set({ navOpen: toggle })
}));
