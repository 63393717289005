import Dashboard from './Dashboard';
import Authentication from './Authentication';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const CoreRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/sign-up" element={<Authentication operation="sign-up" />} />
        <Route path="/login" element={<Authentication operation="login" />} />
        <Route path="/forgot-password" element={<Authentication operation="forgot-password" />} />
      </Routes>
    </Router>
  );
};

export default CoreRoutes;
