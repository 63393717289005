import { useState } from 'react';
import { IconButton, InputAdornment, OutlinedInput, InputLabel, FormControl } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

interface passwordProps {
  handleChange: (p: string) => void;
}

export const PasswordTextField = ({ handleChange }: passwordProps) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handlePasswordChange = (password: string) => {
    handleChange(password);
    setPassword(password);
  };
  const theme = useTheme();
  const textInputStyling = (theme: object | any) => ({
    '& .MuiInputLabel-root': {
      fontFamily: theme.fontFamilies?.inter + ' !important',
      fontSize: '15px !important',
      fontWeight: '400',
      letterSpacing: '-0.5'
    },
    input: {
      fontFamily: theme.fontFamilies?.inter,
      fontSize: '15px',
      fontWeight: '400',
      color: theme.text?.secondary
    },
    '.MuiOutlinedInput-root': {
      border: 'solid 0.5px rgba(255 255 255 0)',
      borderRadius: '16px',
      marginBottom: '12px',
      '&.Mui-focused': {
        border: 'none'
      }
    }
  });
  return (
    <FormControl variant="outlined" sx={textInputStyling(theme)} fullWidth>
      <InputLabel htmlFor="outlined-adornment-password">Password (8+ characters)</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={(e) => handlePasswordChange(e.currentTarget.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label="Password (8+ characters)"
      />
    </FormControl>
  );
};
