/** @format */

import { useEffect } from 'react';
import CoreRoutes from './routes/router';
import { useSpringTheme } from './hooks/useSpringTheme';
import { useAmplitude } from './hooks/useAmplitude';
import { ThemeProvider } from '@mui/material/styles';
import AppBar from './components/AppBar';
import NavigationDrawer from './components/NavigationDrawer';
import { CircularProgress } from '@mui/material';

function App() {
  const { theme, themeLoading } = useSpringTheme();
  const { initAmplitude } = useAmplitude();

  useEffect(() => {
    initAmplitude();
  }, [initAmplitude]);

  return themeLoading && Object.keys(theme).length ? (
    <>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
        }}
        size={40}
        thickness={4}
        value={100}
      />
    </>
  ) : (
    <ThemeProvider {...theme}>
      <AppBar />
      <NavigationDrawer />
      <CoreRoutes />
    </ThemeProvider>
  );
}

export default App;
