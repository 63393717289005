import { useTheme } from '@mui/material/styles';
import { Card, Grid } from '@mui/material';
import './SignUpBanner.css';

interface BannerProps {
  operation: 'sign-up' | 'login' | 'forgot-password';
}

const SignUpBannerImgMobile = ({ operation }: BannerProps) => {
  const theme = useTheme();
  const messageTheme = {
    width: '100%',
    textAlign: 'center',
    color: theme?.palette?.text?.primary,
    fontFamily: theme?.fontFamilies?.inter,
    '& h3': {
      fontSize: '34px',
      maxWidth: '311px',
      lineHeight: '41.99px',
      fontWeight: '700',
      fontFamily: theme?.fontFamilies?.dmSans,
      padding: '0',
      margin: '0px'
    },
    '& h5': {
      fontSize: '24px',
      maxWidth: '311px',
      lineHeight: '32px',
      fontSyle: 'bold',
      fontWeight: '700 !important',
      fontFamily: theme?.fontFamilies?.dmSans,
      padding: '0',
      margin: '0px',
      marginBottom: '12px'
    },
    '& p': {
      fontfamily: theme?.fontFamilies?.inter,
      fontStyle: 'Regular',
      fontSize: '16px',
      padding: '0',
      lineHeight: '28px',
      fontWeight: '400',
      margin: '16px 0 12px 0'
    }
  };

  const getMainTitle = () => {
    return operation === 'forgot-password' ? 'Password reset' : 'Welcome back';
  };

  return (
    <>
      {operation === 'sign-up' ? (
        <div className="spr-banner-img">
          <Grid container justifyContent="center" direction="column" alignItems="center">
            <img src="/img/spring-logo.svg" alt="spring logo" className="spring-logo" />
          </Grid>
        </div>
      ) : (
        <Grid container justifyContent="center" direction="column" alignItems="center">
          <img
            src="/img/spring-logo.svg"
            alt="spring logo"
            style={{ margin: '24px auto 64px', height: '29px', width: '144px' }}
          />
        </Grid>
      )}
      <Card elevation={0}>
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
          sx={messageTheme}
        >
          {operation === 'sign-up' ? (
            <h3>Create & sell products for free.</h3>
          ) : (
            <h5>{getMainTitle()}</h5>
          )}
          {operation === 'sign-up' && <p>Make money in minutes.</p>}
        </Grid>
      </Card>
    </>
  );
};

export default SignUpBannerImgMobile;
