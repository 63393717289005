import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const StandardTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  fontFamily: theme.fontFamilies?.inter + ' !important',
  fontSize: '15px !important',
  fontWeight: '400',
  letterSpacing: '-0.5',
  '& .MuiInputLabel-root': {
    fontFamily: theme.fontFamilies?.inter + ' !important',
    fontSize: '15px !important',
    fontWeight: '400',
    letterSpacing: '-0.5'
  },
  '& .MuiOutlinedInput-root': {
    border: 'solid 0.5px rgba(255 255 255 0)',
    color: theme.text?.secondary,
    fontFamily: theme.fontFamilies?.inter + ' !important',
    fontWeight: '400',
    fontSize: '15px',
    borderRadius: '16px',
    marginBottom: '12px',
    '&.Mui-focused': {
      border: 'none'
    }
  },

  input: {
    fontFamily: theme.fontFamilies?.inter,
    fontSize: '15px',
    fontWeight: '400'
    // letterSpacing: "-0.5px",
  }
}));
