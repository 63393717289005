import Card, { CardProps } from '@mui/material/Card';
import { styled } from '@mui/material/styles';

export const AuthenticationFormCard = styled(Card)<CardProps>(({ theme }) => ({
  paddingTop: '12px',
  width: '311px',
  '& p': {
    fontFamily: theme.fontFamilies?.inter
  },
  background: 'transparent'
}));
