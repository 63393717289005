/** @format */

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Spacer } from '../utils/Spacer';
import { useNavigationStore } from '../../state/navigation';
import { OutlinedTransparentBtn } from '../_customMui/OutlinedTransparentBtn';
import { Grid } from '@mui/material';

export default function SpringAppBar() {
  const { toggleNav, navOpen, showAppBar, appBarStyle } = useNavigationStore();

  return showAppBar ? (
    <AppBar
      position="fixed"
      sx={{
        height: '93px',
        backgroundColor:
          appBarStyle === 'login' || appBarStyle === 'sign-up' || appBarStyle === 'forgot-password'
            ? 'transparent'
            : 'black',
        color: 'black',
        borderBottom:
          appBarStyle === 'login' || appBarStyle === 'forgot-password'
            ? 'solid 1px #E0E0E0'
            : 'none'
      }}
      elevation={0}
    >
      <Toolbar>
        {appBarStyle !== 'login' && appBarStyle !== 'sign-up' && appBarStyle !== 'forgot-password' && (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => toggleNav(!navOpen)}
          >
            <MenuIcon />
          </IconButton>
        )}
        <img src="/img/spring-logo.svg" style={{ height: '29px', margin: '31px 0' }} alt="Spring" />
        <Spacer />

        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="end"
          style={{ padding: '12px' }}
        >
          {appBarStyle !== 'sign-up' && (
            <OutlinedTransparentBtn
              style={{
                width: '98px',
                height: '48px'
              }}
              href="/sign-up"
            >
              Sign up
            </OutlinedTransparentBtn>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  ) : (
    <></>
  );
}
