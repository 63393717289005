import { Card, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const AuthDivider = () => {
  const Hrule = styled('hr')(({ theme }) => ({
    width: '100%',
    borderColor: 'rgba(0, 0, 0, 0.23)'
  }));

  const OrSpan = styled('span')(({ theme }) => ({
    fontFamily: theme.fontFamilies?.inter,
    margin: '0 10px',
    color: '#616161',
    fontWeight: '400',
    fontSize: '12px'
  }));

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Card
        sx={{
          width: '311px',
          backgroundColor: 'transparent',
          margin: '8px 0'
        }}
        elevation={0}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ flexWrap: 'nowrap' }}
        >
          <Hrule />
          <OrSpan>OR</OrSpan>
          <Hrule />
        </Grid>
      </Card>
    </Grid>
  );
};

export default AuthDivider;
