/** @format */

import { Grid, SvgIcon } from '@mui/material';
import { OutlinedTransparentBtn } from '../_customMui/OutlinedTransparentBtn';
import { AuthenticationFormCard } from '../_customMui/AuthenticationFormCard';
import { ReactComponent as facebook } from '../../svg/facebook.svg';
import { ReactComponent as google } from '../../svg/BrandGoogle.svg';
interface SocialProps {
  operation: string;
}

const SocialSignOn = ({ operation }: SocialProps) => {
  const context = operation === 'sign-up' ? 'Sign up' : 'Login';
  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      style={{ padding: '12px' }}
    >
      <AuthenticationFormCard elevation={0}>
        <OutlinedTransparentBtn style={{ marginBottom: '12px' }} fullWidth>
          <SvgIcon component={google} />
          &nbsp;{context} with Google
        </OutlinedTransparentBtn>
        <OutlinedTransparentBtn style={{ marginBottom: '4px' }} fullWidth>
          <SvgIcon component={facebook} />
          &nbsp;{context} with Facebook
        </OutlinedTransparentBtn>
      </AuthenticationFormCard>
    </Grid>
  );
};

export default SocialSignOn;
