import { useEffect, useState } from 'react';
import get from 'lodash/get';
import { getStyleSheet } from '../../api';
import { createTheme } from '@mui/material/styles';
declare module '@mui/material/styles' {
  interface Theme {
    [key: string]: Record<string, any>;
  }
  interface ThemeOptions {
    fontFamilies?: Record<string, any>;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    disabled: true;
  }
}

export const useSpringTheme = () => {
  const [theme, setTheme] = useState({});
  const [themeLoading, setThemeLoading] = useState(true);

  useEffect(() => {
    setThemeLoading(true);
    getStyleSheet().then((res) => {
      const GlobalTheme = get(res, 'global');
      const TypStyle = (root: string) => {
        const themeSlug = String(root).replace('{', '').replace('}', '') + '.value';
        return get(GlobalTheme, themeSlug);
      };
      const newTheme = createTheme({
        palette: {
          mode: 'light',
          common: {
            white: GlobalTheme.Common?.White?.['100p'].value,
            black: GlobalTheme.Common?.Black?.['100p'].value
          },
          text: {
            primary: GlobalTheme.Light?.Text?.Primary.value,
            secondary: GlobalTheme.Light?.Text?.Secondary.value,
            disabled: GlobalTheme.Light?.Text?.Disabled?.value
          },
          primary: {
            main: GlobalTheme.Light?.Primary?.Main.value,
            light: GlobalTheme.Light?.Primary?.Light?.value,
            dark: GlobalTheme.Light?.Primary?.Dark.value,
            contrastText: GlobalTheme.Light?.Primary?.Contrast?.value
          },
          secondary: {
            main: GlobalTheme.Light?.Secondary?.Main.value,
            light: GlobalTheme.Light?.Secondary?.Light?.value,
            dark: GlobalTheme.Light?.Secondary?.Dark.value,
            contrastText: GlobalTheme.Light?.Secondary?.Contrast?.value
          },
          error: {
            main: GlobalTheme.Light?.Warning?.Main.value,
            light: GlobalTheme.Light?.Error?.Light?.value,
            dark: GlobalTheme.Light?.Error?.Dark.value,
            contrastText: GlobalTheme.Light?.Error?.Contrast?.value
          },
          warning: {
            main: GlobalTheme.Light?.Warning?.Main.value,
            light: GlobalTheme.Light?.Warning?.Light?.value,
            dark: GlobalTheme.Light?.Warning?.Dark.value,
            contrastText: GlobalTheme.Light?.Warning?.Contrast?.value
          },
          info: {
            main: GlobalTheme.Light?.Info?.Main.value,
            light: GlobalTheme.Light?.Info?.Light?.value,
            dark: GlobalTheme.Light?.Info?.Dark.value,
            contrastText: GlobalTheme.Light?.Info?.Contrast?.value
          },
          success: {
            main: GlobalTheme.Light?.Success?.Main.value,
            light: GlobalTheme.Light?.Success?.Light?.value,
            dark: GlobalTheme.Light?.Success?.Dark.value,
            contrastText: GlobalTheme.Light?.Success?.Contrast?.value
          },
          background: {
            paper: GlobalTheme.Light?.Background?.Paper?.value,
            default: GlobalTheme.Light?.Background?.Paper?.value
          },
          action: {
            hover: GlobalTheme.Light?.Primary?.Dark.value,
            disabledBackground: GlobalTheme.Light?.Action?.['Disabled Background (12p)'].value,
            disabled: GlobalTheme.Light?.Text?.Disabled?.value
          }
        },
        fontFamilies: {
          inter: GlobalTheme?.fontFamilies.inter.value,
          dmSans: GlobalTheme?.fontFamilies['dm-sans'].value
        },
        typography: {
          h1: {
            fontFamily: TypStyle(GlobalTheme?.Typography?.['H1']?.value?.fontFamily),
            fontWeight: TypStyle(GlobalTheme?.Typography?.['H1']?.value?.fontWeight),
            fontSize: TypStyle(GlobalTheme?.Typography?.['H1']?.value?.fontSize),
            letterSpacing: TypStyle(GlobalTheme?.Typography?.['H1']?.value?.letterSpacing),
            lineHeight: TypStyle(GlobalTheme?.Typography?.['H1']?.value?.lineHeight)
          },
          h2: {
            fontFamily: TypStyle(GlobalTheme?.Typography?.['H2']?.value?.fontFamily),
            fontWeight: TypStyle(GlobalTheme?.Typography?.['H2']?.value?.fontWeight),
            fontSize: TypStyle(GlobalTheme?.Typography?.['H2']?.value?.fontSize) + 'px',
            letterSpacing: TypStyle(GlobalTheme?.Typography?.['H2']?.value?.letterSpacing),
            lineHeight: TypStyle(GlobalTheme?.Typography?.['H2']?.value?.lineHeight)
          },
          h3: {
            fontFamily: TypStyle(GlobalTheme?.Typography?.['H3']?.value?.fontFamily),
            fontWeight: TypStyle(GlobalTheme?.Typography?.['H3']?.value?.fontWeight),
            fontSize: TypStyle(GlobalTheme?.Typography?.['H3']?.value?.fontSize) + 'px',
            letterSpacing: TypStyle(GlobalTheme?.Typography?.['H3']?.value?.letterSpacing),
            lineHeight: TypStyle(GlobalTheme?.Typography?.['H3']?.value?.lineHeight)
          },
          h4: {
            fontFamily: TypStyle(GlobalTheme?.Typography?.['H4']?.value?.fontFamily),
            fontWeight: TypStyle(GlobalTheme?.Typography?.['H4']?.value?.fontWeight),
            fontSize: TypStyle(GlobalTheme?.Typography?.['H4']?.value?.fontSize) + 'px',
            letterSpacing: TypStyle(GlobalTheme?.Typography?.['H4']?.value?.letterSpacing),
            lineHeight: TypStyle(GlobalTheme?.Typography?.['H4']?.value?.lineHeight)
          },
          h5: {
            fontFamily: TypStyle(GlobalTheme?.Typography?.['H5']?.value?.fontFamily),
            fontWeight: TypStyle(GlobalTheme?.Typography?.['H5']?.value?.fontWeight),
            fontSize: TypStyle(GlobalTheme?.Typography?.['H5']?.value?.fontSize) + 'px',
            letterSpacing: TypStyle(GlobalTheme?.Typography?.['H5']?.value?.letterSpacing),
            lineHeight: TypStyle(GlobalTheme?.Typography?.['H5']?.value?.lineHeight)
          },
          h6: {
            fontFamily: TypStyle(GlobalTheme?.Typography?.['H6']?.value?.fontFamily),
            fontWeight: TypStyle(GlobalTheme?.Typography?.['H6']?.value?.fontWeight),
            fontSize: TypStyle(GlobalTheme?.Typography?.['H6']?.value?.fontSize) + 'px',
            letterSpacing: TypStyle(GlobalTheme?.Typography?.['H6']?.value?.letterSpacing),
            lineHeight: TypStyle(GlobalTheme?.Typography?.['H6']?.value?.lineHeight)
          },
          subtitle1: {
            fontFamily: TypStyle(GlobalTheme?.Typography?.['Subtitle 1']?.value?.fontFamily),
            fontWeight: TypStyle(GlobalTheme?.Typography?.['Subtitle 1']?.value?.fontWeight),
            fontSize: TypStyle(GlobalTheme?.Typography?.['Subtitle 1']?.value?.fontSize) + 'px',
            letterSpacing: TypStyle(GlobalTheme?.Typography?.['Subtitle 1']?.value?.letterSpacing),
            lineHeight: TypStyle(GlobalTheme?.Typography?.['Subtitle 1']?.value?.lineHeight)
          },
          subtitle2: {
            fontFamily: TypStyle(GlobalTheme?.Typography?.['Subtitle 2']?.value?.fontFamily),
            fontWeight: TypStyle(GlobalTheme?.Typography?.['Subtitle 2']?.value?.fontWeight),
            fontSize: TypStyle(GlobalTheme?.Typography?.['Subtitle 2']?.value?.fontSize) + 'px',
            letterSpacing: TypStyle(GlobalTheme?.Typography?.['Subtitle 2']?.value?.letterSpacing),
            lineHeight: TypStyle(GlobalTheme?.Typography?.['Subtitle 2']?.value?.lineHeight)
          },
          body1: {
            fontFamily: TypStyle(GlobalTheme?.Typography?.['Body 1']?.value?.fontFamily),
            fontWeight: TypStyle(GlobalTheme?.Typography?.['Body 1']?.value?.fontWeight),
            fontSize: TypStyle(GlobalTheme?.Typography?.['Body 1']?.value?.fontSize) + 'px',
            letterSpacing: TypStyle(GlobalTheme?.Typography?.['Body 1']?.value?.letterSpacing),
            lineHeight: TypStyle(GlobalTheme?.Typography?.['Body 1']?.value?.lineHeight)
          },
          body2: {
            fontFamily: TypStyle(GlobalTheme?.Typography?.['Body 2']?.value?.fontFamily),
            fontWeight: TypStyle(GlobalTheme?.Typography?.['Body 2']?.value?.fontWeight),
            fontSize: TypStyle(GlobalTheme?.Typography?.['Body 2']?.value?.fontSize) + 'px',
            letterSpacing: TypStyle(GlobalTheme?.Typography?.['Body 2']?.value?.letterSpacing),
            lineHeight: TypStyle(GlobalTheme?.Typography?.['Body 2']?.value?.lineHeight)
          },
          caption: {
            fontFamily: TypStyle(GlobalTheme?.Typography?.['Caption']?.value?.fontFamily),
            fontWeight: TypStyle(GlobalTheme?.Typography?.['Caption']?.value?.fontWeight),
            fontSize: TypStyle(GlobalTheme?.Typography?.['Caption']?.value?.fontSize) + 'px',
            letterSpacing: TypStyle(GlobalTheme?.Typography?.['Caption']?.value?.letterSpacing),
            lineHeight: TypStyle(GlobalTheme?.Typography?.['Caption']?.value?.lineHeight)
          }
        }
      });
      setTheme(newTheme);
      setThemeLoading(false);
    });
  }, []);
  return { theme: { theme: theme }, themeLoading: themeLoading };
};
