import { useEffect } from 'react';
import { useAmplitude } from '../../hooks/useAmplitude';
import { DASHBOARD_HOME, VIEWED } from '../../lib/tracking';

import './Dashboard.css';

export const Dashboard = () => {
  const { logEvent } = useAmplitude();

  useEffect(() => {
    logEvent(`${DASHBOARD_HOME}.${VIEWED}`);
  }, [logEvent]);

  return (
    <div className="spr_dashboard__dash_main">
      <span>Dashboard</span>
    </div>
  );
};

export default Dashboard;
